/* eslint-disable react/no-danger */
import React from 'react';

import Layout from '../components/layout';

const archiveTemplate = () => (
  <Layout>
  </Layout>
);

export default archiveTemplate;

